import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

require('../styles/index.scss')

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>Daniel Todd, a London Ruby on Rails and JavaScript developer</title>
        <meta name="description" content="Daniel Todd is an indie maker and founder of Timetoast and Typecurve. He works mainly with Ruby on Rails and React.js, but is also skilled in many other full-stack and DevOps technologies." />
      </Helmet>
      <header className="section site-header">
        <div className="container is-medium is-max-desktop">
          <h1 className="title is-2">Hi, I'm Daniel Todd</h1>
          <h2 className="subtitle">I'm a maker/developer/startup guy.</h2>
        </div>
      </header>

      <section className="section projects">
        <div className="container is-max-desktop">

          <div className="introduction">
            <p>You should have a look at some of my projects:</p>
          </div>

          <article className="block project">
            <div className="project__image">
              <StaticImage
                src="../images/timetoast.jpg"
                alt="A timetoast timeline"
                width={160}
                height={160}
                placeholder="none"
                className="circle-image" />
            </div>
            <div className="project__content">
              <h3 className="title is-4">Timetoast timeline maker</h3>
              <p>A great free timeline maker that's visited by millions of people every month.</p>
              <ul className="project__links">
                <li className="project__link">
                  <a href="https://www.timetoast.com" title="Timetoast timelines">Make timelines with Timetoast</a>
                </li>
              </ul>
            </div>

          </article>

          <article className="block project">
            <div className="project__image">
              <StaticImage
                src="../images/typecurve.jpg"
                alt="Some generative Typecurve posters"
                width={160}
                height={160}
                placeholder="none"
                className="circle-image" />
            </div>
             <div className="project__content">
              <h3 className="title is-4">Typecurve wall art</h3>
              <p>Create beautiful generative artworks using a live art customiser on the website. Once you're done, you can order your custom print and get a truly unique artwork delivered to your home.</p>
              <ul className="project__links">
                <li className="project__link">
                  <a href="https://typecurve.io" title="Typecurve wall art">Make custom art with Typecurve</a>
                </li>
                <li className="project__link">
                  <a href="https://instagram.com/typecurve" title="Typecurve Wall Art on Instagram">Typecurve art on instagram</a>
                </li>
              </ul>
            </div>
          </article>

        </div>
      </section>

      <footer className="footer">
        <div className="container is-max-desktop has-text-centered">
          <p>Find me on:</p>
        </div>
        <ul className="level social-links">
          <li className="level-item">
            <a href="https://twitter.com/ordinathorreur">Twitter</a>
          </li>
          <li className="level-item">
            <a href="https://github.com/ordinathorreur">Github</a>
          </li>
          <li className="level-item">
            <a href="https://instagram.com/ordinathorreur">Instagram</a>
          </li>
          <li className="level-item">
            <a href="https://uk.linkedin.com/in/itsdanieltodd">LinkedIn</a>
          </li>
        </ul>
        <div className="copyright">
          <small>Copyright © Daniel Todd, All rights reserved.</small>
        </div>
      </footer>

    </main>
  )
}

export default IndexPage